<template>
    <transition name="slide-up">
        <div v-show="show" class="sheet">
            <div class="title">
                <img :src="require('@/assets/images/my-page/GoodByeOnsCampaign/campaign_title.png')" alt="" />
            </div>
            <div class="ons-desc" v-html="`*ONS: One Night Stand`" />
            <OnsCampaignEmojiInstruction :page="page" from="modalEnterCampaign" />
            <BottomButton :label="$translate('START_CAMPAIGN')" service="goodbye_ons" @click="enterCampaignPage" />
        </div>
    </transition>
</template>

<script>
import OnsCampaignEmojiInstruction from '@/routes/my-page/components/OnsCampaignEmojiInstruction'

export default {
    name: 'ModalEnterCampaign',
    components: {
        OnsCampaignEmojiInstruction,
    },
    data: () => ({
        show: false,
        page: 0,
    }),
    computed: {
        noOnsParticipate() {
            return this.$store.getters.me.profile.no_ons_campaign || 0
        },
    },
    methods: {
        enterCampaignPage() {
            this.$emit('close')
            if (this.noOnsParticipate === 1) {
                this.$toast.success(this.$translate('CAMPAIGN_ALREADY_PARTICIPATED'))
                return
            } else if (this.noOnsParticipate === -1) {
                this.$toast.error(this.$translate('CAMPAIGN_BANNED_USER'))
                return
            }

            this.$stackRouter.push({ name: 'GoodbyeOnsCampaignPage' })
        },
    },
    mounted() {
        setTimeout(() => {
            this.show = true
        })
    },
}
</script>

<style scoped lang="scss">
.sheet {
    width: 100vw;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px 24px 0 0;

    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;

        img {
            height: 32px;
        }
    }
    .ons-desc {
        color: $grey-06;
        font-size: 14px;
        text-align: center;
        margin-top: 8px;
        margin-bottom: 36px;
    }
}
::v-deep .emoji-instructions {
    .instruction {
        padding: 0;
    }
    .hr {
        display: none;
    }
}
::v-deep .bottom-button {
    position: static;
    padding: 0;
    //background-image: linear-gradient(279deg, #0064fb 100%, #419aff 0%);
}
</style>
